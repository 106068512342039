<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("role.finance.invoicesIn.title") }}
        </div></CCol
      >
    </CRow>

    <CCard>
      <CCardBody>
        <InvoicesInFilters
          ref="filters"
          :filters="filters"
          @onSearch="onSearch(filters)"
        />
        <InvoicesInTable :filters="filters" ref="invoices" />

        <InvoicesPaidModal />
        <InvoicesConfirmModal />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import get from "lodash/get";
import InvoicesInFilters from "./partials/InvoicesInFilters";
import InvoicesInTable from "./partials/InvoicesInTable";
import InvoicesPaidModal from "./share/InvoicesPaidModal";
import InvoicesConfirmModal from "./share/InvoicesConfirmModal";
import subMonths from "date-fns/subMonths";
import EventBus from "../../../helpers/EventBus";
import { parseInvoicingSettings } from "../../../helpers/common";

export default {
  name: "InvoicesIn",

  components: {
    InvoicesInFilters,
    InvoicesInTable,
    InvoicesPaidModal,
    InvoicesConfirmModal,
  },

  provide() {
    const invoicingSettings = parseInvoicingSettings(
      get(this.$store.state.role, "extra.invoicing", null)
    );
    return {
      invoicingSettings: invoicingSettings,
    };
  },

  data() {
    return {
      filters: {
        search: "",
        start: subMonths(new Date(), 12),
        end: new Date(),
      },
    };
  },

  watch: {
    filters: {
      handler(val) {
        this.onSearch(val);
      },
      deep: true,
    },
  },

  mounted() {
    EventBus.$on("invoices:refresh", this.refresh);
  },

  beforeDestroy() {
    EventBus.$off("invoices:refresh", this.refresh);
  },

  methods: {
    onSearch(filters) {
      this.$refs.invoices.doSearch(filters);
    },

    refresh() {
      this.onSearch(this.filters);
    },
  },
};
</script>
